<template>
    <div class="course-card"
         @mouseover="addAnimation(courseData.id)"
         @mouseleave="removeAnimation(courseData.id)">
        <router-link :class="currentId===courseData.id?'slideInUp':''"
                     :to="{path: `/${detailPage}/${courseData.id}`}">

            <div class="top">
                <!--        <img :src="courseData.cover_image_url" alt="">-->
                <img v-lazy="courseData.cover_image_url" alt="">
            </div>
            <div class="desc">
                <div class="first-line" v-show="!hideLine.firstLine">
                    {{ courseData.title || courseData.name }}
                </div>
                <div class="second-line" v-show="!hideLine.secondLine">
                  <template v-if="courseData.course_type ===1">
                    <template v-if="courseData.course_mode === 1">开课时间 {{formatMoment(courseData.started_at, 'YYYY-MM-DD')}}</template>
                  </template>
                  <template v-else-if="courseData.course_type === 2">
                    <template v-if="courseData.course_mode === 1">
                      <template v-if="courseData.class_type === 1">即时上课</template>
                      <template v-if="courseData.class_type === 2">开课时间 {{formatMoment(courseData.started_at, 'YYYY-MM-DD')}}</template>
                    </template>
                    <template v-else-if="courseData.course_mode === 2">
                      有效期 {{courseData.valid_days}} 天
                    </template>
                    <template v-else-if="courseData.course_mode === 3">
                      有效期 {{courseData.valid_days}} 天
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="detailPage=='activityDetail'">
                      开始时间 {{formatMoment(courseData.started_at, 'YYYY-MM-DD')}}
                    </template>
                  </template>

                </div>
                <div class="third-line">
                    <div class="left" v-if="!hideLine.thirdLine.left&&detailPage !== 'material'"
                         v-show="!hideLine.thirdLine.left">
                        已有{{ courseData.enroll_count }}人报名
                    </div>
                    <div class="left" v-else-if="!hideLine.thirdLine.left&&(detailPage === 'material')"
                         v-show="!hideLine.thirdLine.left">
                        已有{{ courseData.enroll_count }}人领取
                    </div>
                    <div class="left" v-else></div>
                    <div v-if="!courseData.guide_price||courseData.guide_price == 0" class="right"
                         v-show="!hideLine.thirdLine.right">免费
                    </div>
                    <div v-else class="right" v-show="!hideLine.thirdLine.right">￥{{ courseData.guide_price }}</div>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
  import {formatMoment} from '@/utils/utils'
export default {

    name: 'CourseCard',
    data () {
        return {
            currentId: ''
        }
    },
    props: {
        detailPage: {
            type: String,
            default: () => {
                return ''
            }
        },
        courseData: {
            type: Object,
            default: () => {
                return {}
            }
        },
        hideLine: {
            type: Object,
            default: () => {
                return {
                    firstLine: false,
                    secondLine: false,
                    thirdLine: {
                        left: false,
                        right: false
                    }
                }
            }
        }
    },
    methods: {
        addAnimation (index) {
            this.currentId = index
        },
        removeAnimation () {
            this.currentId = ''
        },
      formatMoment
    }
}
</script>

<style lang="less">
.course-card {
    width: 192px;
    font-family: "PingFang SC";
    margin-right: 60px;
    cursor: pointer;

    & > a {
        transition: all .2s linear;
        display: block;
        top: 0;

        & > .top {
            width: 192px;
            height: 108px;
            border-radius: 3px;
            overflow: hidden;

            & > img {
                width: 100%;
                height: 100%;
            }
        }

        & > .desc {
            width: 100%;
            margin-top: 10px;
            position: relative;
            height: 100px;

            & > .first-line {
                font-size: 14px;
                color: #333;
                font-weight: bold;
                text-align: left;
                margin-bottom: 5px;
            }

            & > .second-line {
                text-align: left;
                font-size: 12px;
                color: #555;
                margin-bottom: 8px;
            }

            & > .third-line {
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-top: 3px;
                position: absolute;
                left: 0;
                bottom: 18px;
                /*padding: 0 16px;*/
                box-sizing: border-box;

                & > .left {
                    color: #999;
                    font-size: 12px;
                }

                & > .right {
                    color: #FF5864;
                    font-size: 14px;
                    float: right;
                }
            }

        }
    }

    .slideInUp {
        top: -10px;
    }
}

</style>
