<template>
  <div class="ielts-page">
    <div class="ielts-tab-box">
      <div class="ielts-tab">
        <div class="box"></div>
        <div class="ielts-tab-item" v-for="item in ieltsTab" :key="item.url">
          <router-link :to="item.url">
            <div class="" :class="`icon-papa-${item.icon}`">{{item.name}}</div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="ielts-content">
      <router-view name="duolingo"></router-view>
    </div>
    <FloatBar category="duolingo"></FloatBar>
    <FloatAds></FloatAds>

  </div>
</template>

<script>
  import DuolingoJs from './Duolingo.js'

  export default DuolingoJs
</script>

<style lang="less">
  .douingo-page {
    & > .slider-content {
      font-size: 0;

      & > .swiper-container {
        width: 1200px;
        height: 400px;
        margin: 20px auto;

        & > .swiper-wrapper {
          & > .swiper-slide {
            width: 100% !important;
            text-align: center;
            font-size: 18px;
            background: #fff;
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;

            & > img {
              width: 100%;
              height: 400px
            }
          }
        }
      }
    }

    & > .con {
      width: 100%;
      background: #fff;
      float: left;
      padding-top: 80px;

      & > .optimal {
        width: 1200px;
        margin: 0 auto;

        & > .title-box {
          width: 100%;
          display: flex;
          justify-content: space-between;

          & > .left {
            display: flex;

            & > .title {
              .category-title;
              margin-right: 45px;
            }

            & > .category-list {
              display: flex;
              align-items: center;

              & > .category-item {
                padding-right: 2em;
                .cursor;
              }
            }

            & > .type {
              display: flex;

              & > .type-item {
                display: flex;
                align-items: center;
                margin-right: 50px;
                font-size: 16px;
                color: #555555;
                font-weight: 500;
                .cursor;

                & > .line {
                  width: 3px;
                  height: 14px;
                  background: @papaGreen;
                  margin-right: 10px;
                }
              }
            }
          }

          & > .right {
            color: #999;
            font-size: 12px;

            & > a {
              & > i {
                font-size: 12px;
              }
            }
          }
        }

        & > .content {
          margin-top: 35px;

          & > .lists {
            display: flex;
          }
        }
      }

      & > .desc-list {
        width: 1200px;
        margin: 80px auto 0;

        & > .section-title {
          font-size: 22px;
          font-weight: bold;
          color: #333;
          text-align: left;
          margin-bottom: 35px;
        }

        & > .service-list {
          width: 1200px;
          margin: 0 auto;

          & > .item {
            font-size: 0;
            width: 284px;
            height: 420px;
            border: 1px solid #E6E6E6;
            box-sizing: border-box;
            padding: 36px 42px;
            border-radius: 5px;
            float: left;
            margin-right: 21px;
            margin-bottom: 30px;

            & > img {
              width: 72px;
              height: 72px;
            }

            & > .title {
              margin: 32px 0 26px;
              font-size: 22px;
              font-weight: bold;
              color: #333;
            }

            & > .detail {
              color: #555;
              font-size: 16px;
              line-height: 30px;
            }
          }

          & > .item:nth-child(4n) {
            margin-right: 0;
          }
        }


      }

      & > .teacher-desc {
        width: 100%;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        margin-top: 60px;

        & > .title {
          font-size: 30px;
          color: #333;
          font-weight: bold;
          width: 100%;
          text-align: center;
        }

        & > .button-advisory {
          margin: 30px 0 25px;
          width: 120px;
          height: 46px;
          background: @papaGreen;
          text-align: center;
          line-height: 46px;
          color: #fff;
          font-size: 18px;
          border-radius: 3px;
          cursor: pointer;
        }

        & > .bottom {
          display: flex;
          align-items: center;
          margin-bottom: 120px;

          & > .left {
            position: relative;
            margin-right: 45px;

            & > .introduction {
              position: absolute;
              right: 10px;
              top: 302px;
              animation: pte-introduction 3s linear infinite;
            }

            & > .theresa {
              position: absolute;
              top: 88px;
              left: 0;
            }

            & > .teacher {
              position: relative;
              top: 0;
            }
          }

          & > .right {

            & > .item {
              width: 414px;
              border-left: 5px solid #D4F6E7;
              padding: 24px 42px;
              box-sizing: border-box;
              text-align: left;
              box-shadow: 0px 0px 9px 0px rgba(121, 121, 121, 0.08);
              margin-bottom: 30px;

              & > .title {
                font-size: 24px;
                color: #333;
                font-weight: bold;
              }

              & > .desc {
                color: #555;
                font-size: 16px;
              }
            }

            & > .item:nth-child(2) {
              border-left: 5px solid #29D087;
              box-shadow: 0px 3px 21px 0px rgba(121, 121, 121, 0.13);
            }
          }
        }
      }
    }
  }

  @keyframes pte-introduction {
    0% {
      top: 302px;
    }
    25% {
      top: 292px;
    }
    50% {
      top: 280px;
    }
    75% {
      top: 290px;
    }
    100% {
      top: 302px;
    }

  }

  .swiper-pagination-bullet-active {
    background: #fff;
    opacity: 1;
  }

  .swiper-pagination-bullet {
    background: #fff !important;
  }

  /deep/ .my-bullet-active {
    background: #fff;
    opacity: 1;
  }

  .hide {
    display: none;
  }

  .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    color: #fff;
    left: 16px;
    background-image: none !important;


    & > i {
      font-size: 40px;
    }

    i:before {
      margin-right: 0;
    }
  }

  .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    right: 28px;
    color: #fff;
    text-align: left;
    background-image: none !important;


    & > i {
      font-size: 40px;
    }

    i:before {
      margin-right: 0;
    }
  }

  .swiper-button-next:hover {
    color: #29D087;
  }

  .swiper-button-prev:hover {
    color: #29D087;
  }

</style>
