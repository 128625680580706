import FloatBar from '@/components/FloatBar/FloatBar.vue'
import FloatAds from '@/components/FloatAds/FloatAds.vue'
import * as Api from '@/api/index/index'
import CourseCard from '@/components/CourseCard/CourseCard'
import {resolveAddress} from '@/utils/utils'

export default {
  name: 'Pte',
  components: {
    FloatBar,
    CourseCard,
    FloatAds
  },
  data () {
    return {
      swiperBtn: false,
      swiperOption: {
        slidesPerView: 1,
        autoplay: true,
        loop: true,
        centeredSlides: true,
        effect: 'fade',
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          bulletActiveClass: 'my-bullet-active',
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      category_id: 8,
      banners: [],
      optimaData: {
        data: [],
        type: 'courseDetail'
      },
      optimalHideLine: {
        thirdLine: {
          left: true,
          right: false
        }
      },
    }
  },
  mounted() {
    this.getBanner()
    this.getOptimalList()
  },
  computed: {
    optimaDataType () {
      return (item) => {
        switch (item.course_type) {
          case 1:
            return 'pastCourseDetail'
          case 2:
            return 'courseDetail'
          case 3:
            return 'courseDetail'

        }
      }
    }
  },
  methods: {
    advisory () {
      clickHandler()
    },
    getBanner () {
      let params = {
        position:2,
        category_id: this.category_id
      }
      Api.ads(params, (res) => {
        res.forEach(ele => {
          ele.address = resolveAddress(ele)
        })
        this.banners = res
      })
    },
    // 课程优选列表
    getOptimalList () {
      let params = {
        course_type: this.constant.COURSE_TYPE.LIVE_FEE,
        limit: 5,
        category_id: this.category_id,
        is_recommend: 1
      }
      Api.courseRecommend(params, res => {
        this.optimaData.data = res
        this.optimaData.type = 'courseDetail'
      })
    },
    mouseOverSlider () {
      this.swiperBtn = true
    },
    mouseLeaveSlider () {
      this.swiperBtn = false
    }
  },
  metaInfo: {
    title: '趴趴教育唯一官方网站 - 雅思,托福,GMAT走心的在线英语培训平台',
    meta: [
      {
        name: 'keywords',
        content: '趴趴雅思,趴趴托福,趴趴GMAT,趴趴雅思小助手,雅思,托福,GMAT,PTE,实用英语'
      }
    ]
  }
}
